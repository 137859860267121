




















































































































import {
  defineComponent,
  ref,
  ssrRef,
  useContext,
  computed,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';

import {
  BlogPost,
  useBlog
} from '~/composables';

import blogGetters from '~/modules/blog/getters/blogGetters';

import {
  SfHeading,
  SfBreadcrumbs,
  SfImage,
  SfLink,
  SkeletonLoader
} from '~/components';
import PostItem from '../components/PostItem.vue';
import { isClient } from '~/utilities/helpers';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { usePageStore } from '~/stores/page';
import { useBlogStore } from '~/modules/blog/stores/blog';

export default defineComponent({
  name: 'Blog',
  head() {
    return {
      title: this.$i18n.t('Blog') as string
    };
  },
  components: {
    LoadWhenVisible,
    SfHeading,
    SfBreadcrumbs,
    SfImage,
    PostItem,
    SkeletonLoader,
    SfLink,
  },
  setup() {
    const { app: { i18n }, localePath } = useContext();
    const { loadPost, loadingPost } = useBlog();
    const { routeData } = usePageStore();
    const blogStore = useBlogStore();

    const postId = computed(() => routeData.post_id);
    const relatedPosts = ssrRef([]);
    const relatedProducts = ssrRef(null);
    const post = ssrRef<BlogPost | null>(null);

    const breadcrumbs = ref([]);

    useFetch(async () => {
      if (blogStore.storedPosts.some(detailedPost => detailedPost.post_id === postId.value)) {
        post.value = blogStore.storedPosts.find((detailedPost) => detailedPost.post_id === postId.value) || null;
      } else {
        const postData = await loadPost(postId.value);
        post.value = blogGetters.getPost(postData.value);
        relatedPosts.value = blogGetters.getRelatedPosts(postData.value);
        relatedProducts.value = blogGetters.getRelatedProducts(postData.value);

        if (!blogStore.storedPosts.some(storedPost => storedPost.post_id === postId.value)) {
          blogStore.$patch((state) => {
            state.storedPosts.push(post.value as BlogPost);
          });
        }
      }

      breadcrumbs.value = [
        {
          link: localePath('/'),
          text: i18n.t('Home') as string,
        },
        {
          link: localePath('/blog'),
          text: i18n.t('Blog') as string,
        },
        {
          link: '#',
          text: blogGetters.getTitle(post.value)
        }
      ];
    });

    const currentUrl = ref();

    onMounted(() => {
      currentUrl.value = isClient ? window.location.href : '';
    });

    return {
      breadcrumbs,
      loadingPost,
      post,
      relatedPosts,
      relatedProducts,
      currentUrl,
      ...blogGetters,
    };
  }
});
